.image-preview {
    position: relative;
    text-align: center;
  }
  
  .image-preview > img {
    width: 100%;
  }
  
  /* @media(max-width:768px){
    .demo-image-preview > img {
      width: 100%;
    }
  } */
  
  /* fullscreen enable by props */
  .image-preview-fullscreen > img {
    width: 100vw;
    height:100vh;
  }

  .react-html5-camera-photo > video {
    width: 100%;
  }