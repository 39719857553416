.o-form {
    display: flex;
    flex-direction: column;
}

.o-form-field {
    display: flex;
    justify-content: space-between;
}

.o-full-width {
    width: 100%;
}